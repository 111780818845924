import { Box, Text } from '@chakra-ui/react';
import { PageTitleDescriptionProps } from './page-title-description.types';
import { useState } from 'react';
import { TextLink } from '@meritcircle/ui/components/text-link/text-link';
import useTranslation from 'next-translate/useTranslation';

export const PageTitleDescription = ({
  description = '',
  maxLength,
  ...rest
}: PageTitleDescriptionProps) => {
  const [isReadMore, setIsReadMore] = useState(true);
  const { t: tt } = useTranslation('rewards');

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  if (description.length <= maxLength) {
    return <Text>{description}</Text>;
  }

  return (
    <Box {...rest} w="100%">
      <Text>{isReadMore ? `${description.slice(0, maxLength)}...` : description}</Text>
      {description.length > 100 && (
        <TextLink onClick={toggleReadMore} size={'sm'} style={{ cursor: 'pointer' }}>
          {isReadMore ? tt(`readmore`) : tt(`showless`)}
        </TextLink>
      )}
    </Box>
  );
};
