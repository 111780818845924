import { Box, Heading } from '@chakra-ui/react';
import { PageTitleProps } from './page-title.types';
import { PageTitleDescription } from '../page-title-description/page-title-description';

export const PageTitle = ({
  title,
  description,
  titleLevel = 'h1',
  titleSize = 'display',
  ...rest
}: PageTitleProps) => {
  return (
    <Box {...rest} w="100%">
      <Heading as={titleLevel} size={titleSize} variant="glowing" color="lightPurple" mb="space.32">
        {title}
      </Heading>
      <PageTitleDescription description={description} maxLength={100} />
    </Box>
  );
};
