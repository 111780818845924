
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { TimelineItem } from '@/client/graphql/types.generated';
import { useUserTimelineQuery } from '@/client/graphql/user-timeline.generated';
import { BaseLayout, ErrorPage } from '@/components/templates';
import { withUrql } from '@/config/graphql';
import { Box, Container, Flex, Heading } from '@chakra-ui/react';
import { IconName, TextualHero, TileProps, Timeline } from '@meritcircle/ui/components';
import { NextPage, GetServerSideProps, InferGetServerSidePropsType } from 'next';
import useTranslation from 'next-translate/useTranslation';
import { getTimeLineGroups } from '@/components/features/dashboard/timeline.helpers';
import { BACKEND_URLS, URLS } from '@/config/urls';
import InView from 'react-intersection-observer';
import { useMemo, useState } from 'react';
import { contentfulClient } from '@cms/client';
import { DashboardPageDocument, DashboardPageTopGamesDocument, DashboardPageTopArticlesDocument, DashboardPageQuery, DashboardPageTopGamesQuery, DashboardPageTopArticlesQuery, DashboardPageRewardQuery, DashboardPageRewardDocument, DashboardPageRewardQueryVariables, } from '@cms/graphql/dashboardPage.generated';
import { useCurrentUser } from '@/utils/hooks/useCurrentUser';
import { useRouter } from 'next/router';
import { useRedirect } from '@/utils/hooks';
import { Seo } from '@/components/utils/seo/seo-component';
import { GamesSection } from '@/components/features/dashboard/games-section/games-section';
import { RewardsSection } from '@/components/features/dashboard/rewards-section/rewards-section';
import { TopArticlesSection } from '@/components/features/dashboard/top-articles-section/top-articles-section';
import { TextLink } from '@meritcircle/ui/components/text-link/text-link';
import { getGamesUrl } from '@/utils/games';
import { SiteLinksSearchBoxJsonLd } from 'next-seo';
import { getOpenGraphProps } from '@/components/utils/seo/get-open-graph-props';
const TIMELINE_ITEMS_AMOUNT = 10;
type PageProps = InferGetServerSidePropsType<typeof getServerSideProps>;
const Page: NextPage<PageProps> = props => {
    const { metaTitle, metaDescription, ogImage, noFollow, noIndex, keywords, linkedSeoTags, linkedGiveawayPage, title, subtitle, topArticlesCollection, topGamesCollection, rewardsContent, } = props;
    const currentUser = useCurrentUser();
    const router = useRouter();
    const { redirect } = useRedirect();
    const [timelineItemsAmount, setTimelineItemsAmount] = useState(TIMELINE_ITEMS_AMOUNT);
    const topGames: TileProps[] = topGamesCollection?.items.map(game => ({
        title: game.title,
        image: {
            alt: game.mainImage?.description,
            src: game.mainImage?.url
        },
        href: getGamesUrl(game.urlSlug),
        tags: game.categories?.flatMap(category => ({ children: category })),
        badges: game.gameTagsCollection?.items.flatMap(tag => ({ children: tag.name }))
    }));
    const [{ data: timelineData, error }] = useUserTimelineQuery();
    const { t } = useTranslation('dashboard');
    const groups = useMemo(() => {
        if (!timelineData)
            return [];
        const userTimeline = (timelineData?.userTimeline || []) as TimelineItem[];
        return getTimeLineGroups(userTimeline.slice(0, timelineItemsAmount), t);
    }, [timelineData, timelineItemsAmount, t]);
    const handleTimelineInfiniteLoad = () => {
        setTimelineItemsAmount(timelineItemsAmount + TIMELINE_ITEMS_AMOUNT);
    };
    const canonicalUrl = process.env.SITE_URL;
    if (error) {
        return <ErrorPage />;
    }
    return (<BaseLayout>
      <Seo canonical={canonicalUrl} title={metaTitle || t('meta.title')} description={metaDescription || t('meta.description')} nofollow={linkedSeoTags?.noFollow || noFollow} noindex={linkedSeoTags?.noIndex || noIndex} keywords={linkedSeoTags?.keywords || keywords} openGraph={getOpenGraphProps({
            title: metaTitle || t('meta.title'),
            description: metaDescription || t('meta.description'),
            url: `${canonicalUrl}${router.route}`,
            image: ogImage
        })}/>
      <SiteLinksSearchBoxJsonLd url={canonicalUrl} potentialActions={[
            {
                target: `${URLS.SEARCH}?search`,
                queryInput: 'search_term_string'
            },
        ]}/>
      <TextualHero title={title} subtitle={!currentUser.fetchingUserData &&
            !currentUser.userLoggedInBefore &&
            !currentUser.isLoggedIn
            ? subtitle.json
            : undefined} cta={!currentUser.fetchingUserData &&
            !currentUser.userLoggedInBefore &&
            !currentUser.isLoggedIn
            ? {
                label: t('common:navigation.register'),
                url: BACKEND_URLS.REGISTER,
                onClick: () => {
                    redirect(BACKEND_URLS.REGISTER, router.asPath);
                }
            }
            : undefined}/>
      <GamesSection games={topGames}/>
      {rewardsContent && linkedGiveawayPage?.slug && (<RewardsSection giveawaySlug={linkedGiveawayPage.slug} title={rewardsContent?.title} cta={{
                label: t('common:labels.take-me-there'),
                url: `${URLS.REWARDS}/${rewardsContent?.slug}`
            }} backgroundImage={rewardsContent?.image?.url}/>)}
      <Container>
        <Box pb={{ base: '4xl', lg: '5xl' }} position="relative" mt={{ base: '2xl', lg: '6xl' }}>
          <Flex justifyContent="space-between" flexWrap={{ base: 'wrap', lg: 'nowrap' }}>
            <Box flexBasis={{ base: '100%', lg: '40%' }}>
              <Heading size="h1" as="h2" mb="4" variant="glowing">
                {t('top-articles.title')}
              </Heading>
              <TextLink mb="8" href={URLS.ACADEMY} rightIcon={{ icon: IconName.arrowRightFill }}>
                {t('top-articles.button-text')}
              </TextLink>
              <TopArticlesSection articles={topArticlesCollection.items}/>
            </Box>
            <Flex flexBasis={{ base: '100%', lg: '50%' }} flexDirection="column" mt={{ base: '3xl', lg: '0' }}>
              <Heading size="h1" as="h2" mb="xl" variant="glowing">
                {t('timeline')}
              </Heading>
              <Timeline groups={groups} todayLabel={t('common:date.today')} yesterdayLabel={t('common:date.yesterday')}/>
              {!!groups.length && (<InView rootMargin="0px 0px 200px 0px" onChange={inView => inView && handleTimelineInfiniteLoad()}/>)}
            </Flex>
          </Flex>
        </Box>
      </Container>
    </BaseLayout>);
};
const getServerSideProps: GetServerSideProps<DashboardPageQuery['pageDashboardCollection']['items'][number] & {
    topGamesCollection: DashboardPageTopGamesQuery['pageDashboardCollection']['items'][number]['topGamesCollection'];
    topArticlesCollection: DashboardPageTopArticlesQuery['pageDashboardCollection']['items'][number]['topArticlesCollection'];
    rewardsContent: DashboardPageRewardQuery['pageGiveawayCollection']['items'][number] | null;
}> = async () => {
    const [dashboardPromise, topGamesPromise, topArticlesPromise] = await Promise.all([
        contentfulClient.query<DashboardPageQuery>(DashboardPageDocument).toPromise(),
        contentfulClient.query<DashboardPageTopGamesQuery>(DashboardPageTopGamesDocument).toPromise(),
        contentfulClient
            .query<DashboardPageTopArticlesQuery>(DashboardPageTopArticlesDocument)
            .toPromise(),
    ]);
    const pageContent = dashboardPromise.data?.pageDashboardCollection.items[0];
    const topArticlesContent = topArticlesPromise.data?.pageDashboardCollection.items[0];
    const topGamesContent = topGamesPromise.data?.pageDashboardCollection.items[0];
    if (!pageContent || !topArticlesContent || !topGamesContent) {
        console.log('👻 Dashboard page data is missing');
        return {
            notFound: true
        };
    }
    const { topGamesCollection } = topGamesContent;
    const { topArticlesCollection } = topArticlesContent;
    const rewardsCollectionFromContentful = await contentfulClient
        .query<DashboardPageRewardQuery, DashboardPageRewardQueryVariables>(DashboardPageRewardDocument, {
        slug: pageContent.linkedGiveawayPage?.slug
    })
        .toPromise();
    const rewardsContent = rewardsCollectionFromContentful.data?.pageGiveawayCollection?.items[0] || null;
    return {
        props: {
            ...pageContent,
            topGamesCollection,
            topArticlesCollection,
            rewardsContent
        }
    };
};
export default withUrql(Page);

    async function __Next_Translate__getServerSideProps__19275bd268d__(ctx) {
      const res = await getServerSideProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getServerSideProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || (() => Promise.resolve({})),
          }))
        }
      }
    }
    export { __Next_Translate__getServerSideProps__19275bd268d__ as getServerSideProps }
  